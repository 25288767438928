// <!-- UTILITIES -->
import { min, max } from 'date-fns';
import { DateRange } from '@/utils/filters';

// <!-- TYPES -->
/** @typedef {import('@/utils/datetime').IDate} IDate */
/** @typedef {import('@/utils/datetime').IInterval} IInterval */
/** @typedef {import('@/utils/datetime').ITimestamp} ITimestamp */

/**
 * Get latest start date from a pair of intervals.
 * @param {Readonly<IInterval>} a
 * @param {Readonly<IInterval>} b
 * @returns {IDate}
 */
const getLatestStartDate = (a, b) => {
    const isStartDateAValid = !Number.isNaN(a.start.valueOf());
    const isStartDateBValid = !Number.isNaN(b.start.valueOf());
    if (isStartDateAValid && isStartDateBValid) {
        // When both are valid, return the maximum.
        return max([a.start, b.start]);
    }
    if (!isStartDateAValid && !isStartDateBValid) {
        // When neither are valid, return `NaN`.
        return NaN;
    }
    // When only one is valid, return the valid one.
    return isStartDateAValid ? a.start : b.start;
};

/**
 * Get earliest end date from a pair of intervals.
 * @param {Readonly<IInterval>} a
 * @param {Readonly<IInterval>} b
 * @returns {IDate}
 */
const getEarliestEndDate = (a, b) => {
    const isEndDateAValid = !Number.isNaN(a.end.valueOf());
    const isEndDateBValid = !Number.isNaN(b.end.valueOf());
    if (isEndDateAValid && isEndDateBValid) {
        // When both are valid, return the minimum.
        return min([a.end, b.end]);
    }
    if (!isEndDateAValid && !isEndDateBValid) {
        // When neither are valid, return `NaN`.
        return NaN;
    }
    // When only one is valid, return the valid one.
    return isEndDateAValid ? a.end : b.end;
};

/**
 * Get the latest start date and earliest end date from a set of intervals.
 * @param {Iterable<Readonly<[ start: IDate, end: IDate ]>>} [intervals]
 * @returns {IInterval}
 */
export const getDateRangeOverlap = (intervals = []) => {
    const narrowRange = DateRange.create({ start: NaN, end: NaN });
    for (const [start, end] of intervals ?? []) {
        const interval = DateRange.create({ start, end });
        narrowRange.start = getLatestStartDate(narrowRange, interval);
        narrowRange.end = getEarliestEndDate(narrowRange, interval);
    }
    // If no intervals are provided, return default range. Otherwise, returns modified interval.
    return narrowRange;
};

// <!-- DEFAULT -->
export default getDateRangeOverlap;
