<template>
    <SidebarFilter
        :title="title"
        :border="border"
    >
        <template #content>
            <div
                class="flex flex-row items-baseline space-x-2"
                @focusout="onFocusOutMenuDropdown"
            >
                <!-- PRESET DROPDOWN -->
                <span class="w-20"></span>
                <MenuDropdown
                    id="dropdown-preset"
                    class="dropdown__preset w-full"
                    title="Preset"
                    variant="white"
                    :class="{
                        'text-gray-400': isLoading,
                        'pointer-events-none': isLoading,
                        'cursor-not-allowed': isLoading,
                    }"
                    :style="{ 'z-index': 3 }"
                    :options="options"
                />
            </div>
            <FormKit
                type="form"
                help="Set date range filter."
                :actions="false"
                :config="config"
                #default="context"
            >
                <!-- INTERVAL: DATE START -->
                <FormKit
                    id="filter-date-start"
                    type="date"
                    label="Start:"
                    name="start"
                    outer-class="$reset outer"
                    wrapper-class="$reset wrapper flex flex-row items-baseline space-x-2"
                    label-class="$reset label w-20 text-sm"
                    inner-class="$reset inner block w-full"
                    validation-label="Start date"
                    @node="registerFormKitNode"
                    @input="onDateTimeLocalInput"
                    @keyup.enter="onDateTimeLocalKeyboardEvent('start', $event)"
                    @click="onDateTimeLocalClicked('start', $event)"
                    :delay="1000"
                    :value="formattedCleanStartDate"
                    :input-class="`$reset input appearance-none block w-full mt-2 px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 sm:text-sm ${
                        getNodeValidity('start') !== true
                            ? 'input__valid border-2 border-red-500'
                            : ''
                    } ${
                        isDateInputDisabled === true
                            ? 'text-gray-500 cursor-not-allowed'
                            : 'text-black focus:outline-none focus:ring-primary-500 focus:border-primary-500'
                    }`"
                    :validation="[
                        ['required'],
                        // ['date_before', new Date(dirtyData.end).toISOString()],
                    ]"
                    :readonly="isDateInputDisabled"
                />
                <!-- INTERVAL: DATE END -->
                <FormKit
                    id="filter-date-end"
                    type="date"
                    label="End:"
                    name="end"
                    outer-class="$reset outer"
                    wrapper-class="$reset wrapper flex flex-row items-baseline space-x-2"
                    label-class="$reset label w-20 text-sm"
                    inner-class="$reset inner block w-full"
                    validation-label="End date"
                    @node="registerFormKitNode"
                    @input="onDateTimeLocalInput"
                    @keyup.enter="onDateTimeLocalKeyboardEvent('end', $event)"
                    @click="onDateTimeLocalClicked('end', $event)"
                    :delay="1000"
                    :value="formattedCleanEndDate"
                    :input-class="`$reset input appearance-none block w-full mt-2 px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 sm:text-sm ${
                        getNodeValidity('end') !== true
                            ? 'input__valid border-2 border-red-500'
                            : ''
                    } ${
                        isDateInputDisabled === true
                            ? 'text-gray-500 cursor-not-allowed'
                            : 'text-black focus:outline-none focus:ring-primary-500 focus:border-primary-500'
                    }`"
                    :validation="[
                        ['required'],
                        // ['date_after', new Date(dirtyData.start).toISOString()],
                    ]"
                    :readonly="isDateInputDisabled"
                    :disabled="isLoading"
                />
                <!-- MODIFIER: ALL -->
                <div
                    id="filter-date-all"
                    class="toggle__modifier"
                >
                    <SwitchGroup
                        as="div"
                        class="flex items-center justify-between pt-2"
                    >
                        <span class="flex">
                            <SwitchLabel
                                as="span"
                                class="text-sm font-medium text-gray-900"
                                passive
                                >All Dates
                            </SwitchLabel>
                            <BaseTooltip
                                target="#tooltip-target"
                                :text="DateRangeFilterTooltips.all"
                            >
                                <template #icon
                                    ><InformationCircleIcon
                                        class="relative inline-block left-1 -top-1 h-6 w-6 sm:h-4 sm:w-4 text-primary-700 cursor-help"
                                        aria-hidden="true"
                                /></template>
                            </BaseTooltip>
                        </span>
                        <Switch
                            v-model="dirtyData.all"
                            :class="[
                                isAllModifierEnabled
                                    ? 'bg-primary-600'
                                    : 'bg-gray-200',
                                'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500',
                            ]"
                        >
                            <span
                                aria-hidden="true"
                                :class="[
                                    isAllModifierEnabled
                                        ? 'translate-x-5'
                                        : 'translate-x-0',
                                    'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
                                ]"
                            />
                        </Switch>
                    </SwitchGroup>
                </div>
                <!-- MODIFIER: OVERLAP -->
                <div
                    id="filter-date-overlap"
                    class="toggle__modifier"
                >
                    <SwitchGroup
                        as="div"
                        class="flex items-center justify-between pt-2"
                    >
                        <span class="flex">
                            <SwitchLabel
                                as="span"
                                class="text-sm font-medium text-gray-900"
                                passive
                                >Overlapping Dates
                            </SwitchLabel>
                            <BaseTooltip
                                target="#tooltip-target"
                                :text="DateRangeFilterTooltips.overlap"
                            >
                                <template #icon
                                    ><InformationCircleIcon
                                        class="relative inline-block left-1 -top-1 h-6 w-6 sm:h-4 sm:w-4 text-primary-700 cursor-help"
                                        aria-hidden="true"
                                /></template>
                            </BaseTooltip>
                        </span>
                        <Switch
                            v-model="dirtyData.overlap"
                            :class="[
                                isOverlapModifierEnabled
                                    ? 'bg-primary-600'
                                    : 'bg-gray-200',
                                'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500',
                            ]"
                        >
                            <span
                                aria-hidden="true"
                                :class="[
                                    isOverlapModifierEnabled
                                        ? 'translate-x-5'
                                        : 'translate-x-0',
                                    'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
                                ]"
                            />
                        </Switch>
                    </SwitchGroup>
                </div>
                <!-- DEBUG -->
                <div
                    v-if="!!debug"
                    id="debug__info"
                    class="bg-gray-200 m-2 p-2 indent-0.5"
                >
                    <pre
                        class="text-xs subpixel-antialiased whitespace-pre-wrap break-words"
                        >{{ getDebugInfo(context) }}</pre
                    >
                </div>
            </FormKit>
        </template>
    </SidebarFilter>
</template>

<script>
    // <!-- API -->
    import {
        defineComponent,
        watch,
        ref,
        onMounted,
        onBeforeUnmount,
    } from 'vue';
    import debounce from 'just-debounce-it';

    // <!-- COMPONENTS -->
    import SidebarFilter from '@/components/sidebar/SidebarFilter.vue';
    import MenuDropdown from '@/components/inputs/MenuDropdown.vue';
    import BaseTooltip from '@/components/tooltips/BaseTooltip.vue';
    import { Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue';

    // <!-- UTILITIES -->
    import { useFormkitDebugger } from '@/utils/FormKitDebugger';

    // <!-- COMPOSABLES -->
    import { useDateRangeFilter } from '@/components/sidebar/hooks/useDateRangeFilter';

    // <!-- TYPES -->
    /** @typedef {import('@formkit/core').FormKitConfig} FormKitConfig */

    // <!-- ICONS -->
    import { InformationCircleIcon } from '@heroicons/vue/outline';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'DateRangeFilter',
        components: {
            SidebarFilter,
            MenuDropdown,
            Switch,
            SwitchGroup,
            SwitchLabel,
            BaseTooltip,
            InformationCircleIcon,
        },
        props: {
            /** Filter section title. */
            title: {
                /** @type {V.PropType<String>} */
                type: String,
                required: true,
            },
            /** If true, show the top border. */
            border: {
                /** @type {V.PropType<Boolean>} */
                type: Boolean,
                default: true,
            },
            /** Debug mode. */
            debug: {
                type: Boolean,
                default: false, // HACK: Set to true to get debug mode content.
            },
        },
        setup(props, context) {
            /** @type {V.Ref<import('vue').WatchStopHandle[]>} */
            const handles = ref([]);

            // <!-- DESTRUCTURE -->
            const { state, constants, properties, methods, watchers } =
                useDateRangeFilter();

            // <!-- STATE -->
            const { config, options, formID, cleanData, dirtyData } = state;

            // <!-- CONSTANTS -->
            const { DateRangeFilterTooltips } = constants;

            // <!-- CONDITIONALS -->
            const {
                isLoading,
                isDropdownEmpty,
                isDateInputDisabled,
                isAllModifierEnabled,
                isOverlapModifierEnabled,
                formattedCleanStartDate,
                formattedCleanEndDate,
            } = properties;

            // <!-- METHODS -->
            const { actions, getters, handlers } = methods;

            // <!-- GETTERS -->
            const { getNodeValidity } = getters;

            // <!-- ACTIONS -->
            const { initialize, registerFormKitNode } = actions;

            // <!-- HANDLERS -->
            const {
                // INPUT LISTENERS
                onFocusOutMenuDropdown,
                onDateTimeLocalInput,
                onDateTimeLocalClicked,
                onDateTimeLocalKeyboardEvent,
            } = handlers;

            // <!-- WATCHERS -->
            const {
                watchAllDatesToggle,
                watchOverlappingDatesToggle,
                watchDateRangeInterval,
                watchDateRangeModifiers,
                watchLocationsFilter,
                watchWeatherStationsFilter,
            } = watchers;

            // <!-- DEBUG -->
            const { getDebugInfo } = useFormkitDebugger(
                formID,
                cleanData,
                dirtyData
            );

            // <!-- LIFECYCLE -->

            // Initializes FormKit and loads in current store data.
            initialize();

            // Registers the watcher callbacks.
            onMounted(() => {
                handles.value = [
                    watchAllDatesToggle(),
                    watchOverlappingDatesToggle(),
                    watchDateRangeModifiers(),
                    watchDateRangeInterval(),
                    watchLocationsFilter(),
                    watchWeatherStationsFilter(),
                ];
            });

            // Removes the watcher callbacks before unmounting.
            onBeforeUnmount(() => {
                handles.value?.forEach((h) => h?.());
                handles.value = [];
            });

            // <!-- EXPOSE -->
            return {
                // STATE
                config,
                options,
                dirtyData,
                // CONSTANTS
                DateRangeFilterTooltips,
                // PROPERTIES
                isLoading,
                isDropdownEmpty,
                isDateInputDisabled,
                isAllModifierEnabled,
                isOverlapModifierEnabled,
                formattedCleanStartDate,
                formattedCleanEndDate,
                // METHODS
                getNodeValidity,
                registerFormKitNode,
                onFocusOutMenuDropdown,
                onDateTimeLocalInput: debounce(onDateTimeLocalInput, 300, true),
                onDateTimeLocalClicked: debounce(
                    onDateTimeLocalClicked,
                    500,
                    true
                ),
                onDateTimeLocalKeyboardEvent: debounce(
                    onDateTimeLocalKeyboardEvent,
                    300,
                    true
                ),
                getDebugInfo,
            };
        },
    });
</script>
