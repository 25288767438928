// <!-- UTILITIES -->
import isNil from 'lodash-es/isNil';
import { isPointWithinInterval } from '@/features/analysis/utils/isPointWithinInterval';

// <!-- TYPES -->
/** @typedef {import('@/utils/datetime').IDate} IDate */
/** @typedef {import('@/utils/datetime').IInterval} IInterval */
/** @typedef {import('@/utils/datetime').ITimestamp} ITimestamp */

/**
 *
 * @param {Iterable<Readonly<[ x: IDate, y: any ]>>} points
 * @param {Readonly<IInterval>} interval
 * @returns {Readonly<[ x: IDate, y: any ]>[]}
 */
export const filterPointsByInterval = (points, interval) => {
    const collection = [...(points ?? [])];
    const isCollectionPresent = !isNil(points);
    const isCollectionEmpty = isCollectionPresent && collection.length > 0;
    const filtered = isCollectionEmpty
        ? []
        : collection.filter((point) => isPointWithinInterval(point, interval));
    return filtered;
};

// <!-- DEFAULT -->
export default filterPointsByInterval;
