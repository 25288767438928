// <!-- API -->
import { computed, ref } from 'vue';

// <!-- UTILITIES -->
import isNil from 'lodash-es/isNil';
import clone from 'just-clone';

// <!-- TYPES -->
/** @typedef {import('@/utils/options').IOption} */
/** @typedef {import('@/utils/options').IDropdownOption} IDropdownOption */
/** @typedef {import('@/utils/options').IOptionSelected} IOptionSelected */

/**
 * Define component interface.
 */
export const useDropdown = () => {
    // <!-- LOCAL TYPES -->
    /** @typedef {ReturnType<useState>} IDropdownState */
    /** @typedef {ReturnType<useProperties>} IDropdownProperties */
    /** @typedef {ReturnType<useMethods>} IDropdownMethods */
    /**
     * @typedef {Object} IDropdownBehaviour
     * @property {IDropdownState} state
     * @property {IDropdownProperties} properties
     * @property {IDropdownMethods} methods
     */

    // <!-- COMPOSABLES -->
    /**
     * Define reactive state.
     */
    const useState = () => {
        // <!-- DEFINE -->
        /** @type {V.Ref<IDropdownOption[]>} Ordered collection of dropdown options. */
        const options = ref([]);
        // <!-- EXPOSE -->
        return {
            options,
        };
    };

    /**
     * Define computed properties.
     * @param {Pick<IDropdownBehaviour, 'state'>} context
     */
    const useProperties = (context) => {
        const { state } = context;
        // <!-- HELPERS -->
        /** Define computed conditionals. */
        const useConditionals = () => {
            // <!-- DEFINE -->
            /** @type {V.ComputedRef<Boolean>} */
            const isDropdownEmpty = computed(() => {
                const options = state.options.value;
                return isNil(options) || options.length === 0;
            });
            /** @type {V.ComputedRef<Boolean>} */
            const isDropdownNotEmpty = computed(() => {
                const options = state.options.value;
                return !isNil(options) && options.length > 0;
            });
            // <!-- EXPOSE -->
            return {
                isDropdownEmpty,
                isDropdownNotEmpty,
            };
        };
        /** Define computed collections. */
        const useCollections = () => {
            // <!-- DEFINE -->
            /** @type {V.ComputedRef<String[]>} */
            const labels = computed(() => {
                const options = state.options.value;
                const labels = options.map((option) => option.label);
                return labels;
            });
            /** @type {V.ComputedRef<String[]>} */
            const values = computed(() => {
                const options = state.options.value;
                const values = options.map((option) => option.value);
                return values;
            });
            /** @type {V.ComputedRef<([ value: String, label: String ])[]>} */
            const entries = computed(() => {
                const options = state.options.value;
                const entries = options.map((option) => {
                    /** @type {[ value: String, label: String ]} */
                    const entry = [option.value, option.label];
                    return entry;
                });
                return entries;
            });
            // <!-- EXPOSE -->
            return {
                labels,
                values,
                entries,
            };
        };
        /** Define computed property mappings (i.e., transforms). */
        const useTransforms = () => {
            // <!-- DEFINE -->
            /** @type {V.ComputedRef<Record<IDropdownOption['value'], IDropdownOption>>} */
            const optionsRecord = computed(() => {
                const options = state.options.value;
                const entries = options.map((option) => {
                    /** @type {[ value: String, option: IDropdownOption ]} */
                    const entry = [option.value, option];
                    return entry;
                });
                const record = Object.fromEntries(entries);
                return record;
            });
            /** @type {V.ComputedRef<Map<IDropdownOption['value'], IDropdownOption>>} */
            const optionsMap = computed(() => {
                const options = state.options.value;
                const entries = options.map((option) => {
                    /** @type {[ value: String, option: IDropdownOption ]} */
                    const entry = [option.value, option];
                    return entry;
                });
                const map = new Map(entries);
                return map;
            });
            // <!-- EXPOSE -->
            return {
                optionsRecord,
                optionsMap,
            };
        };
        // <!-- DEFINE -->
        const transforms = useTransforms();
        const collections = useCollections();
        const conditionals = useConditionals();
        // <!-- EXPOSE -->
        return {
            ...transforms,
            ...collections,
            ...conditionals,
        };
    };

    /**
     * Define component methods.
     * @param {Pick<IDropdownBehaviour, 'state' | 'properties'>} context
     */
    const useMethods = (context) => {
        const { state, properties } = context;
        // <!-- HELPERS -->
        /** Define conditional queries. */
        const useQueries = () => {
            // <!-- DEFINE -->
            /**
             * Does the specified value correspond to an existing option?
             * @param {String} value Option value.
             * @returns {Boolean} `true` if option is found.
             */
            const hasOption = (value) => {
                const isNotEmpty = properties.isDropdownNotEmpty.value === true;
                if (isNotEmpty) {
                    const optionsMap = properties.optionsMap.value;
                    return optionsMap.has(value);
                }
                return false;
            };
            // <!-- EXPOSE -->
            return {
                hasOption,
            };
        };
        /** Define functional methods. */
        const useActions = () => {
            // <!-- DEFINE -->
            /**
             * Overwrite options array.
             * @param {Readonly<Iterable<IDropdownOption>>} options
             */
            const setOptions = (options) => {
                const collection = [...options];
                if (isNil(collection) || collection.length === 0) {
                    clearOptions();
                } else {
                    state.options.value = collection;
                }
            };
            /**
             * Insert or overwrite option in the options array.
             * @param {IDropdownOption} option
             */
            const setOption = (option) => {
                if (!isNil(option)) {
                    const options = clone(state.options.value);
                    if (query.hasOption(option.value)) {
                        // If existing option is found, replace it.
                        const id = option.value;
                        const index = options.findIndex(
                            (option) => option.value === id
                        );
                        options[index] = option;
                    } else {
                        // If new, distinct option, insert it at end of array.
                        options.push(option);
                    }
                    // Replace reference and update computed properties.
                    state.options.value = options;
                    return;
                }
            };
            /**
             * Remove existing option from the options array.
             * @param {String} value
             */
            const removeOption = (value) => {
                if (!isNil(value)) {
                    // If value is valid.
                    const options = clone(state.options.value);
                    if (query.hasOption(value)) {
                        // If existing option is found, remove it.
                        const id = value;
                        const filtered = options.filter(
                            (option) => option.value === id
                        );
                        state.options.value = filtered;
                    }
                }
            };
            /**
             * Remove all existing options from the options array.
             */
            const clearOptions = () => {
                const isNotEmpty = properties.isDropdownNotEmpty.value === true;
                if (isNotEmpty) {
                    state.options.value = [];
                    return;
                }
            };
            // <!-- EXPOSE -->
            return {
                setOption,
                setOptions,
                removeOption,
                clearOptions,
            };
        };
        // <!-- DEFINE -->
        const query = useQueries();
        const actions = useActions();
        // <!-- EXPOSE -->
        return {
            ...query,
            ...actions,
        };
    };

    // <!-- DEFINE -->
    const state = useState();
    const properties = useProperties({ state });
    const methods = useMethods({ state, properties });

    // <!-- EXPOSE -->
    return {
        state,
        properties,
        methods,
    };
};

// <!-- DEFAULT -->
export default useDropdown;
