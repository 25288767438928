<template>
    <Menu
        as="div"
        class="dropdown__menu relative inline-block text-left"
    >
        <template #default>
            <!-- MENU BUTTON: -->
            <MenuButton
                class="inline-flex w-full justify-center rounded-md border px-4 py-2 text-sm font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
                :class="{
                    // BORDER
                    'border-primary-700 hover:border-primary-700':
                        isPrimaryVariant,
                    'border-gray-300 hover:border-gray-300': isWhiteVariant,
                    // FOCUS
                    'focus:ring-primary-500 focus:ring-offset-primary-100':
                        isPrimaryVariant,
                    'focus:ring-primary-500 focus:ring-offset-gray-100':
                        isWhiteVariant,
                    // BACKGROUND
                    'bg-primary-700 hover:bg-white': isPrimaryVariant,
                    'bg-white hover:bg-gray-50': isWhiteVariant,
                    // TEXT
                    'text-white hover:text-black': isPrimaryVariant,
                    'text-gray-700 hover:text-primary-500': isWhiteVariant,
                }"
            >
                <span>{{ title }}</span>
                <ChevronDownIcon
                    class="-mr-1 ml-2 h-5 w-5"
                    aria-hidden="true"
                />
            </MenuButton>

            <!-- MENU ITEMS: -->
            <transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
            >
                <MenuItems
                    class="absolute right-0 z-10 mt-2 w-full origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                    <div class="">
                        <MenuItem
                            v-for="option of state.options.value"
                            :key="option.value"
                            @click="option.onSelected(option)"
                            #default="{ active }"
                        >
                            <a
                                href="#"
                                :class="[
                                    active
                                        ? 'bg-gray-100 text-gray-900'
                                        : 'text-gray-700',
                                    'block px-4 py-2 text-sm',
                                ]"
                                >{{ option.label }}</a
                            >
                        </MenuItem>
                    </div>
                </MenuItems>
            </transition>
        </template>
    </Menu>
</template>

<script>
    // <!-- API -->
    import { defineComponent, watchEffect, toRefs, computed } from 'vue';

    // <!-- UTILITIES -->
    import { DropdownOption } from '@/utils/options';

    // <!-- COMPOSABLES -->
    import { useDropdown } from '@/components/inputs/hooks/useDropdown';

    // <!-- COMPONENTS -->
    import { Menu, MenuButton, MenuItems } from '@headlessui/vue';
    import { ChevronDownIcon } from '@heroicons/vue/outline';
    import { MenuItem } from '@headlessui/vue';

    /** @typedef {import('@/utils/options').IDropdownOption} IDropdownOption */
    /** @typedef {import('@/utils/options').IOptionSelected} IOptionSelected */

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'MenuDropdown',
        components: {
            Menu,
            MenuButton,
            MenuItems,
            MenuItem,
            ChevronDownIcon,
        },
        props: {
            /** Variant style. */
            variant: {
                /** @type {V.PropType<'primary' | 'white' | String>} */
                type: String,
                default: 'white',
            },
            /** Dropdown menu title. */
            title: {
                /** @type {V.PropType<String>} */
                type: String,
                default: 'Dropdown',
            },
            /** Options schema. */
            options: {
                /** @type {V.PropType<IDropdownOption[]>} */
                type: Array,
                default: () => [
                    DropdownOption.create('Example 1', '1'),
                    DropdownOption.create('Example 2', '2'),
                    DropdownOption.create('Example 3', '3'),
                ],
            },
        },
        setup(props, context) {
            // <!-- DESTRUCTURE -->
            const { variant } = toRefs(props);

            // <!-- DEFINE -->
            const { state, properties, methods } = useDropdown();

            // <!-- CONDITIONALS -->
            /** @type {V.ComputedRef<Boolean>} */
            const isPrimaryVariant = computed(() => {
                const id = variant.value;
                return id === 'primary';
            });
            /** @type {V.ComputedRef<Boolean>} */
            const isWhiteVariant = computed(() => {
                const id = variant.value;
                return id === 'white';
            });

            // <!-- WATCHERS -->
            // Update the dropdown options whenever the prop's dropdown options changes.
            watchEffect(() => {
                methods.setOptions(props.options);
            });

            // <!-- EXPOSE -->
            return {
                state,
                isDropdownEmpty: properties.isDropdownEmpty,
                isPrimaryVariant,
                isWhiteVariant,
            };
        },
    });
</script>
